export function windowRedirect(urlParams) {
  return (window.location.href = urlParams);
}

export function windowOpen(urlParams) {
  return window.open(urlParams, '_blank');
}

export function formatTimestamp(timestamp) {
  return new Date(timestamp).toLocaleDateString('en-nz');
}